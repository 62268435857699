import {
  ImageStyle,
  MediaIcon,
  MediaImage,
} from '../../../generated/queries/preview-queries';
import { getImageSrc } from '../../utils';
import { assetUrl } from '../../utils/assets';

// Applies to MediaImage | MediaIcon
export const getImageStyleUrl = (
  image:
    | {
        styles: Array<{ id: string; url: string } | null | undefined>;
      }
    | null
    | undefined,
  styleName: ImageStyleName,
) => {
  const styles = image?.styles as Array<ImageStyle> | undefined;
  return getUrlFromStyles(styles, styleName);
};

// Generic helper that can also apply to Image fields.
export const getUrlFromStyles = (
  styles: Maybe<Array<ImageStyle>>,
  styleName: ImageStyleName,
) => {
  const style = styles?.find((imageStyle) => imageStyle.id == styleName);
  if (style !== undefined) {
    const { url } = style;
    return assetUrl(url);
  }
  return undefined;
};

export const transformImageFragmentToProps = (
  image:
    | MediaImage
    | MediaIcon
    | OverlaysQuery['allOverlays']['group'][0]['nodes'][0]['overlayImage'],
  imageStyles?: Array<ImageStyleName>,
) => {
  const styles = image?.styles as unknown as
    | Record<ImageStyleName, ImageStyle>
    | undefined;
  return {
    src: assetUrl(getImageSrc(image, image?.url) || ''),
    // @todo probably use mimeType as other transform functions are using it
    filemime: image?.mimeType,
    alt: image?.alt,
    title: image?.title,
    width: image?.width,
    height: image?.height,
    styles: Object.entries(styles || {})
      .filter(
        ([name]) =>
          !imageStyles || imageStyles?.includes(name as ImageStyleName),
      )
      .reduce((o, [key, value]) => {
        o[key] = value;
        return o;
      }, {} as Record<string, ImageStyle>),
  };
};

export type ImageStyleName =
  | 'accordion_item'
  | 'blog_teaser'
  | 'breaking_news_large'
  | 'breaking_news_medium'
  | 'breaking_news_portrait'
  | 'breaking_news_small'
  | 'desktop_standard'
  | 'desktop_wide'
  | 'desktop_super_wide'
  | 'header_image'
  | 'header_slider'
  | 'header_slider_image_1280_x_540_crop'
  | 'header_slider_image_responsive_'
  | 'home_slider_background_icon'
  | 'image_entity_browser_thumbnail'
  | 'laptop_wide'
  | 'large'
  | 'large_cropped'
  | 'linkit_result_thumbnail'
  | 'logo_big'
  | 'logo_small'
  | 'medium'
  | 'medium_cropped'
  | 'mobile'
  | 'mobile_slider_background'
  | 'mobile_small'
  | 'person_teaser'
  | 'related_articles'
  | 'section_background_image'
  | 'self'
  | 'small'
  | 'small_cropped'
  | 'square_big'
  | 'square_medium'
  | 'teaser'
  | 'teaser_large'
  | 'thumbnail';
